import React, { useState } from 'react';
import { Button } from 'reactstrap';
import * as Icon from 'react-feather';
import Axios from 'axios';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import './reg2.css'


export default function Reg2() {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
      companyName: '',
      industry: '',
      companyPhone: '',
      companyEmail: '',
      country: '',
      state: '',
      password: '',
      fullname: '',
      email: '',
      phone: '',
      position: '',
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Handle form submission logic, e.g., send data to server
    //   console.log('Form submitted:', formData);
    Axios.post('http://213.199.48.86:3001/insertCompany',{
        companyName:formData.companyName,
        companyEmail:formData.companyEmail,
        companyPhone:formData.companyPhone,
        industry:formData.industry,
        country:formData.country,
        state:formData.state
      })
  
      Axios.post('http://213.199.48.86:3001/create-company-folder', {
        
        company:formData.companyName
        })
       .then(response => {
        //  setMessage(response.data.message);
       })
       .catch(error => {
        //  setMessage(error.response.data.message);
       });
  
      Axios.post('http://213.199.48.86:3001/insertMember',{
        fullname:formData.fullname,
        company:formData.companyName,
        email:formData.email,
        phone:formData.phone,
        position:formData.position,
        password:formData.password,
        isLogged:'Yes'
      })
  
      createUserWithEmailAndPassword(auth,formData.email,formData.password)
      navigate('/auth/login')
    };
  
    const nextStep = () => {
      setStep(step + 1);
    };
  
    const prevStep = () => {
      setStep(step - 1);
    };
  
    // Calculate progress percentage
    const progress = (step / 3) * 100;

  return (
    <div className="multi-step-form">

    <div className="regLeft">
      <div className="leftBox">
        <br />
        <br />
        <br />
        <h2>The Simplest way to manage your board activities</h2>
        <img className='demoImage' src="/assets/images/1.jpg" alt="" />
      
        <div className="pointers">
            <p><Icon.UserCheck size='16px' style={{color:'gray'}}/> Minutes generation</p>
            <p><Icon.Calendar size='16px' style={{color:'gray'}}/> Meeting scheduling $ Invitations</p>
            <p><Icon.FilePlus size='16px' style={{color:'gray'}}/> Files management</p>
            <p><Icon.Clipboard size='16px' style={{color:'gray'}}/> Voting & Decision Making</p>
            <p><Icon.Activity  size='16px' style={{color:'gray'}}/> Projects & Task Tracking</p>
        </div>
       
      </div>
       

    </div>
    <div className="regRight">
      <div className="changeWidth">
    {step === 1 && (
     <form onSubmit={nextStep} className='regForm'>
      <div className="stepsContainer">
        <div className='cont' >
        <img src="/assets/images/logo.png" className='logo' alt="" />
      
      <hr />
      <h4>Company Details</h4>
      <div className="fields">
        <input type="text" placeholder='Company Name' name="companyName" value={formData.companyName} onChange={handleChange}  />
      </div>
      <div className="fields">
        <select  id="" name="industry" value={formData.industry} onChange={handleChange} >
          <option>Select Industry</option>
          <option value="Accomodation and Food Services" > Accomodation and Food Services</option>
          <option value="Administration">Administration</option>
          <option value="Agriculture,Forestry,Fishing & Hunting">Agriculture,Forestry,Fishing & Hunting</option>
          <option value="Arts,Entertainment & Recreation">Arts,Entertainment & Recreation</option>
          <option value="Construction">Construction</option>
          <option value="Educational Services">Educational Services</option>
          <option value="Finance and Insurance">Finance and Insurance</option>
          <option value="Healthcare and Social Assistance">Healthcare and social Assistance</option>
          <option value="Information Technonology & Software">Information Technology & Software</option>
          <option value="Manufacturing">Manufacturing</option>
          <option value="Mining">Mining</option>
          <option value="Waste Management Services">Waste Management Services</option>
        </select>
      </div>
      <div className='fields2'>
      <input id='cEmail' type="text" placeholder='Company Email' name="companyEmail" value={formData.companyEmail}  onChange={handleChange} />

        <input type="text" placeholder='Company phone (e.g 254 712345678)' name="companyPhone" value={formData.companyPhone} onChange={handleChange}/>

      </div>
      <div className='fields2'>
        <select  id="" name="country" value={formData.country} onChange={handleChange} >
          <option> Select Country</option>
          <option value="Kenya"> Kenya</option>
        </select>

        <input type="text" placeholder='State/Province/County' name="state" value={formData.state} onChange={handleChange}  />

      </div>
      <Button
        color="danger"
        type="submit"
       
        style={{marginBottom:'3%', marginTop:'5%',width:'65%'}}
        
      >
        Next
      </Button>
      <p>Already have an Accout? <a href="/auth/login">Log in</a></p>
      </div>  
      </div>
   </form>
      
    )}

    {step === 2 && (
      <form onSubmit={nextStep} className='regForm'>
    <div className="stepsContainer">
    <img src="/assets/images/logo.png" className='logo' alt="" />
    <hr />
      <div className="cont">
        
    <h4>Personal Details</h4>
    <div className="fields">
    <input type="text" placeholder='Fullname' name="fullname" value={formData.fullname} onChange={handleChange} />
    </div>
      <div className='fields'>
        
        <input type="text" placeholder='Email' name="email" value={formData.email} onChange={handleChange} />

      </div>
     
      <div className='fields'>
        <input type="text" placeholder='Phone (e.g 254 712345678)' name="phone" value={formData.phone} onChange={handleChange} />
      </div>
      <div className="fields">
      <select  id="" name="position" value={formData.position} onChange={handleChange}>
          <option>Position</option>
          <option value="Director"> Director</option>
          <option value="Administrator"> Administrator</option>
          <option value="Secretary"> Secretary</option>
        </select>
      </div>
      <div className="fields">
       <input type="password" placeholder='Password' name="password" value={formData.password} onChange={handleChange} />
      </div>
      <div className="fields">
       <input type="password" placeholder='Confirm Password'  />
      </div>
    <div className="formButtons">
    <Button
        color="primary"
        onClick={handleSubmit}
       
        style={{marginBottom:'1%', marginTop:'5%',width:'32%'}}
        
      >
        Submit
      </Button>

      <Button
        color="danger"
        onClick={prevStep}
       
        style={{marginBottom:'1%', marginTop:'5%', marginLeft:'3%',width:'30%'}}
        
      >
        Previous
      </Button>

    </div>
     
      <p>Already have an Accout? <a href="http://localhost:3000/auth/login">Log in</a></p>
      </div>  
      </div>
      </form>
    )}

    {step === 3 && (
      <div className='cont'>
        <h2>Step 3: Review Information</h2>
        <p>Name: {formData.name}</p>
        <p>Email: {formData.email}</p>
        <p>Age: {formData.age}</p>
        <p>Address: {formData.address}</p>
        {/* Display other form data as needed */}
        <button type="button" onClick={prevStep}>Previous</button>
        <button type="submit" onClick={handleSubmit}>Submit</button>
      </div>
    )}
    
    </div>
    </div>
  </div>
);
  
}
